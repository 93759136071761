.main{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.container{
    display: grid;
    grid-template-columns: 0.1fr 0.8fr 0.1fr;
    grid-gap: 20px;
    width: 80%;
    padding: 10px 10px;
}

.container h4{
    margin: 9px 0px;
}

.container > div{
    justify-content: center;
}

.inputContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
}

.input{
    width: 97%;
    padding: 10px;
    outline: none;
}

.button{
    padding: 11px 30px;
    border: none;
    outline: none;
    color: white;
    background-color: #56CCF2;
    cursor: pointer;
}

.error{
    color: #FF6B6B;
}